import {
  get, post, hapus, update, upload, download, uploadProyekId,
} from './helpers'

function getAllProyek(data) {
  return get('/proyek/list', data)
}

function getProyek(ProyekId) {
  return get(`/proyek/${ProyekId}`)
}

function getUserProyek(ProyekId, jenisUser, data) {
  return get(`/proyek/list-user/${ProyekId}/${jenisUser}`, data)
}

function getListUserProyek(ProyekId, jenisUser, data) {
  return get(`/proyek/list-user-proyek/${ProyekId}/${jenisUser}`, data)
}

function insProyek(data) {
  return post('/proyek', data)
}

function insUserProyek(data) {
  return post('/proyek/userProyek', data)
}

function delUserProyek(ProyekId, UserId, softDelete) {
  return hapus(`/proyek/userProyek/${ProyekId}/${UserId}/${softDelete}`)
}

function ubahStatusUserProyek(ProyekId, UserId, status) {
  return get(`/proyek/ubahStatusUserProyek/${ProyekId}/${UserId}/${status}`)
}

function ubahStatusUserProyekMass(data, ProyekId, status) {
  return post(`/proyek/ubahStatusUserProyekMass/${ProyekId}/${status}`, data)
}

function ubahProyek($data) {
  return update('/proyek', $data)
}

function deleteProyek($data) {
  return hapus('/proyek', $data)
}

function uploadFileProyek(file, metadata, onUploadProgress) {
  return upload('/proyek/uploadfile', file, metadata, onUploadProgress)
}

function getJenisKelamin() {
  return get('/master/jenis/kelamin')
}

function getProvinsi() {
  return get('/master/provinsi')
}

function getKota(provinsiId) {
  return get(`/master/kota/${provinsiId}`)
}

function uploadFileExcel(file, metadata, onUploadProgress, id) {
  return uploadProyekId('/import/excel', file, metadata, onUploadProgress, id)
}

function exportExcel() {
  return download('template-excel/exportExcel')
}

function importFromLog(data) {
  return post('/proyek/importFromLog', data)
}

/* eslint-disable import/prefer-default-export */
export {
  getAllProyek,
  getProyek,
  getUserProyek,
  getListUserProyek,
  insProyek,
  insUserProyek,
  delUserProyek,
  ubahStatusUserProyek,
  ubahStatusUserProyekMass,
  ubahProyek,
  uploadFileProyek,
  getJenisKelamin,
  getProvinsi,
  getKota,
  deleteProyek,
  uploadFileExcel,
  exportExcel,
  importFromLog,
}
