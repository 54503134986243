<template>

  <div>

    <list-filters
      :nama-user-filter.sync="namaUserFilter"
      :jenis-kelamin-filter.sync="jenisKelaminFilter"
      :provinsi-filter.sync="provinsiFilter"
      :kota-filter.sync="kotaFilter"
      :proyek-filter.sync="proyekFilter"
      :umur-awal-filter.sync="umurAwalFilter"
      :umur-akhir-filter.sync="umurAkhirFilter"
      :tagAdminOptions="tagAdminOptions.options"
      :tagPublikOptions="tagPublikOptions.options"
      :proyekOptions="proyekOptions.options"
      :provinsiOptions="provinsiOptions.options"
      :kotaOptions="kotaOptions.options"
      :jenisKelaminOptions="jenisKelaminOptions.options"
      :new-filter.sync="newFilter"
      :btn-key.sync="btnKey"
      @emitKota="emitKota"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />-->
              <b-button
                variant="primary"
                @click="exportExcel"
              >
                <span class="text-nowrap">Download Laporan</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      class="d-flex justify-content-center mb-1"
      style="position: fixed;top:50%;left:50%"
      v-if="showLoader"
    >
      <b-card>
        <b-card-text>
          <strong>Sedang menunggu proses export data</strong>
        </b-card-text>
        <b-spinner
          v-for="variant in variants"
          :key="variant"
          :variant="variant"
          class="mr-1"
          type="grow"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BButton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  getAllProyek, getJenisKelamin, getProvinsi, getKota,
} from '@/api/proyek'
import {
  exportExcel, getTag,
} from '@/connection-api/master'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/daftar-user-project/storeModule'
import useList from '@/connection-api/daftar-user-project/list'
import listFilters from './ListFilters.vue'

export default {
  components: {
    listFilters,

    BCard,
    BCardText,
    BRow,
    BCol,
    // BFormInput,
    BTable,
    // BMedia,
    // BAvatar,
    // BLink,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    // BFormInput,
    BSpinner,
    BButton,
    vSelect,
  },
  data() {
    return {
      showLoader: false,
      variants: ['primary', 'secondary', 'danger', 'warning', 'success', 'info', 'primary'],
      proyekOptions: {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      },
      tagAdminOptions: {
        selected: 'id',
        options: [],
      },
      tagPublikOptions: {
        selected: 'id',
        options: [],
      },
      jenisKelaminOptions: {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      },
      provinsiOptions: {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      },
      kotaOptions: {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      },
      searchId: 0,
    }
  },
  async mounted() {
    const tagAdmin = await getTag(1)
    this.mappingSelectOption(tagAdmin.data, this.tagAdminOptions.options)
    const tagPublik = await getTag(2)
    this.mappingSelectOption(tagPublik.data, this.tagPublikOptions.options)
    // console
    const response = await getAllProyek({ byme: true })
    this.mappingSelectOption(response.data.data, this.proyekOptions.options)

    const responseJK = await getJenisKelamin()
    this.mappingSelectOption(responseJK.data, this.jenisKelaminOptions.options)

    const responsePRV = await getProvinsi()
    this.mappingSelectOption(responsePRV.data, this.provinsiOptions.options)
    // this.emitKota()
  },
  methods: {
    async emitKota(provinsiId) {
      this.kotaOptions = {
        selected: 'id',
        options: [
          { value: null, label: 'Pilih Kota' },
        ],
      }
      const responseKota = await getKota(provinsiId)
      this.mappingSelectOption(responseKota.data, this.kotaOptions.options)
    },
    async exportExcel() {
      this.showLoader = true
      const filter = {
        filterData: this.newFilter,
      }
      const result = await exportExcel(filter)
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'daftar_user_proyek.xlsx')
      document.body.appendChild(link)
      link.click()
      this.showLoader = false
      window.location.reload()
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'daftar-user-project'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      namaUserFilter,
      jenisKelaminFilter,
      provinsiFilter,
      kotaFilter,
      proyekFilter,
      umurAwalFilter,
      umurAkhirFilter,
      newFilter,
      btnKey,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      namaUserFilter,
      jenisKelaminFilter,
      provinsiFilter,
      kotaFilter,
      proyekFilter,
      umurAwalFilter,
      umurAkhirFilter,
      newFilter,
      btnKey,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
